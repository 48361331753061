export const ptBr = <const>{
  title: {
    default: 'Itens da receita',
    confirmSelection: 'Confirme os itens selecionados',
  },
  protectedTitle: 'Acesso Bloqueado',
  preventUndoDispensationTooltip:
    'Apenas a loja dispensadora pode desfazer uma dispensação.',
  dispensateError: 'Não foi possível dispensar a receita',
  undoDispensationError: 'Não foi possível desfazer a dispensação',
  emptyPreviewTitle: 'Não há itens nessa prescrição',
  emptyPreviewContent: 'Sem itens para serem exibidos.',
  primaryButton: {
    login: 'Acesse',
    disabled: 'Selecione acima',
    dispensate_one: 'Dispensar item',
    dispensate_other: 'Dispensar itens',
    undoDispensation: 'Desfazer dispensação',
    confirmDispesation: 'Finalizar e gerar PDF',
    replaceItem: 'Substituir item',
    downloadFile: 'Baixar PDF',
  },
  secondaryButton: {
    backToSearch: 'Voltar',
    cancel: 'Cancelar',
    reviewItens: 'Revisar itens',
    downloadCopy: 'Imprimir registro',
  },
  patientCodeTitle: 'Por segurança, para acessar o PDF digite o CÓDIGO DO PACIENTE',
  patientCodeError: 'Código inválido, verifique e tente novamente. Caso o erro persista, verifique o código na prescrição ou entre em contato com o médico prescritor.',
  patientCodeErrorRetry: 'Código inválido, verifique e tente novamente. Caso o erro persista, verifique o código na prescrição ou entre em contato com o médico prescritor.\n\nVocê tem {{attemptsLeft}} tentativas restantes.',
  patientCodeErrorBlock: 'Limite de tentativas excedido. Aguarde {{blockingMinutes}} minutos para tentar novamente.',
}
