import type {
  PreviewRxResponse,
  RxPatientResponse,
  RxResponse,
} from 'features/RxSearch/types'
import apiRoutes from 'service/api-routes'
import { logError } from 'service/logger'

import { protectedApi } from '../service'
import { isRxResponse, sanitizeRxResponse } from '../utils'

type fetchPatient = {
  rxCode: string
  patientCode: string
}

type downloadPDF = {
  url: string
  patientCode: string
}

const prescriptionApi = {
  previewPrescription: (code: string) =>
    protectedApi.get<PreviewRxResponse>(apiRoutes.previewRx(code)),

  searchPrescription: (code: string) =>
    protectedApi.get<RxResponse>(apiRoutes.searchRx(code), {
      transformResponse(data) {
        if (!data || typeof data !== 'string') return data

        try {
          const json = JSON.parse(data)

          if (isRxResponse(json)) {
            return sanitizeRxResponse(json)
          }
        } catch (error) {
          logError(error)
        }

        return data
      },
    }),

  fetchPatient: ({
    rxCode,
    patientCode,
  }: fetchPatient) =>
    protectedApi.post<RxPatientResponse>
    (apiRoutes.patientRx(rxCode), JSON.stringify({
      patientCode,
    })),

  downloadPatientPdf: ({
    url,
    patientCode,
  }: downloadPDF) =>
    protectedApi.post(url, {
      patientCode,
    }, {
      headers: {
        Accept: 'application/json',
      },
      responseType: 'blob',
    }),
}

export default prescriptionApi
