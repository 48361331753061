export const ptBr = <const>{
  discoverRxCode: {
    title: 'Como descubro o código?',
    subtitle: 'É bem simples, de duas maneiras:',
    topic1: 'WhatsApp do paciente',
    topic2: 'PDF gerado pelo médico.',
  },
  otherTools: {
    title: 'Não é iClinic? Tente outro serviço de validação',
    iti: 'iti',
    itiLink: 'https://assinaturadigital.iti.gov.br/farmaceutico/',
  },
  information: {
    title: 'Para mais dúvidas entre em contato',
    email: 'suporte@iclinicrx.com.br',
  },
  howToDispense: {
    access: 'Acesse',
    yourAccount: 'sua conta',
    or: 'ou',
    register: 'faça seu cadastro',
    questions: 'para mais dúvidas, entre em contato: ',
    email: 'suporte@iclinicrx.com.br',
    title: 'Como Dispensar?',
    logedInTitle: 'Ajuda',
  },
  whereFindMyCode: {
    title: 'Como descubro o código?',
    item1: 'Pela mensagem recebida no whatsapp com o link da receita.',
    item2: 'Pelo .pdf gerado pelo médico, no canto inferior direito.',
  },
  whereFindMyPatientCode: {
    title: 'Como descubro o código do paciente?',
    item1: 'No .pdf gerado pelo médico, no canto inferior direito abaixo do ID da receita.',
  },
  banner: {
    title: 'Que tal cadastrar várias lojas de uma vez só? É bem fácil!',
    content:
      'Cadastre agora e amplie o acesso da sua rede para milhões de prescrições do iClinic.',
    button: 'Clique aqui e saiba como',
  },
  faq: {
    title: 'Perguntas Frequentes',
    content: {
      1: {
        question: 'Preciso do cadastro para dispensar?',
        awnser:
          'Sim, apenas as farmácias cadastradas podem acessar os dados da receita e fazer a dispensação. Para fazer seu cadastro',
        linkText: 'clique aqui',
        queryParams: '?openModal=register',
      },
      2: {
        question:
          'Já fiz o cadastro, mas está com erro para entrar o que faço? ',
        awnser:
          'Lembre-se de acessar com o mesmo e-mail informado no cadastro. Caso não lembre a senha',
        awnserRest:
          'Para outras dúvidas, entre em contato com nosso suporte: suporte@iclinicrx.com.br',
        linkText: 'altere por aqui',
        queryParams: '?openModal=recoverPassword',
      },
      3: {
        question:
          'Por que não consigo acessar a receita, nem receber e-mails de vocês?',
        awnser: 'Pode ser um erro temporário, tente acessar o',
        awnserRest: `pelo seu celular sem o wi-fi. Se der certo, pode ser um bloqueio de acesso da sua rede de farmácias. 
          Entre em contato com a TI da sua rede e peça a liberação dos domínios:
          .iclinic.com.br  
          .iclinicrx.com.br`,
        linkText: 'site do dispensador',
      },
    },
  },
}
